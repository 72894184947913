<template>
  <div>
    <div v-if="!addVisible">
      <div class="card" style="display: flex;">
        <el-button type="primary" style="height: 40px;" @click="handleAdd">新增打印模板</el-button>
        <div class="line"></div>
        <el-form :model="form" :inline="true" class="form">
          <el-form-item label="模板名称">
            <el-input v-model="form.template_name" placeholder="请填写模板名称" clearable></el-input>
          </el-form-item>
          <el-form-item label="模板类型">
            <el-select v-model="form.type" filterable clearable>
              <template v-for="item in typeList">
                <el-option :label="item.name" :value="item.type" :key="item.type"></el-option>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item label="创建人">
            <el-select v-model="form.store_user_id" filterable clearable>
              <template v-for="item in employeeList">
                <el-option :label="item.realname" :value="item.store_user_id" :key="item.store_user_id"></el-option>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-date-picker
              v-model="form.create_time"
              type="daterange"
              align="right"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getList">搜索</el-button>
            <el-button>重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="table">
        <el-table :data="tableData.list" stripe height="700" v-loading="loading" :row-class-name="handleIndex" @row-dblclick="handleDbl">
          <template v-for="item in rowList">
            <el-table-column
              :key="item.field_alias"
              align="center"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.t_width"
            >
            </el-table-column>
          </template>
          <el-table-column align="center" prop="action" label="操作">
            <template slot-scope="scope">
              <div class="options">
                <el-select
                  v-model="scope.row.select"
                  placeholder="请选择"
                  @change="handleChange($event, scope.row)"
                >
                  <el-option label="编辑" :value="1"></el-option>
                  <el-option label="删除" :value="2"></el-option>
                  <el-option label="复制模板" :value="3"></el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagina">
        <el-pagination
          background
          :current-page="page"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="$store.state.pageSizes"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableData.total"
        >
        </el-pagination>
      </div>
    </div>
    <Add v-if="addVisible" :key="currentIndex" @onBack="addVisible = false; currRow = {};" @getList="getList" :row="currRow" />
    <el-dialog
      title="复制模板"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      >
      <el-form :model="addForm" :inline="true" label-width="100px">
        <el-form-item label="模板名称">
          <el-input v-model="addForm.template_name" placeholder="请输入模板名称"></el-input>
        </el-form-item>

        <el-form-item label="选择门店">
          <el-select v-model="addForm.hierarchy_id" placeholder="请选择门店">
            <el-option
              v-for="item in shopList"
              :key="item.hierarchy_id"
              :label="item.hierarchy_name"
              :value="item.hierarchy_id">
            </el-option>
          </el-select>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleTrue" :loading="copyLoading">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getCustomTemplateListReq, getCustomTemplateTypeReq, getEmployeeReq, templateDelReq, copyCustomTemplateReq } from '@/api/system/print/customPrint';
import Add from './Add';
import { getMerchantListReq } from '@/api/goods/historyInv/index';
export default {
  data() {
    return {
      copyLoading: false,
      addForm: {},
      dialogVisible: false,
      currRow: {
        // print_template_local_id: 98,
      },
      addVisible: false,
      employeeList: [],
      typeList: [],
      form: {},
      page: 1,
      limit: this.$store.state.pageSizes[0],
      tableData: {
        list: [],
        total: 0,
      },
      loading: false,
      rowList: [
        { field_text: "序号", field_alias: "index" },
        { field_text: "模板名称", field_alias: "template_name" },
        { field_text: "模板类型", field_alias: "type_name" },
        { field_text: "模板尺寸(mm)", field_alias: "page_size" },
        { field_text: "创建人", field_alias: "store_user_name" },
        { field_text: "创建时间", field_alias: "create_time" },
      ],
      currentIndex:0,
      shopList:[],
    };
  },
  components: {
    Add,
  },
  created() {
    this.getCustomTemplateType();
    this.getEmployee();
    this.getList();
    this.initData();
  },
  watch:{
     '$route': 'initData',
  },
  methods: {

    // /store/common.select/getMerchantList
    //门店列表
    getMerchantList() {
      getMerchantListReq({is_all:1}).then((res) => {
        if (res.code === 1) {
          this.shopList = res.data;
        }
      });
    },

    initData(){
        console.log(this.$route)
       if(this.$route.params.type=='edit'){
          this.currentIndex++
          this.handleChange(1,JSON.parse(this.$route.params.obj))
       }
    },

    handleTrue() {
      this.copyLoading = true;
      copyCustomTemplateReq({
        print_template_local_id: this.currRow.print_template_local_id,
        template_name: this.addForm.template_name,
        hierarchy_id:this.addForm.hierarchy_id,
      })
        .then((res) => {
          this.copyLoading = false;
          if (res.code === 1) {
            this.handleClose();
            this.getList();
          }
        })
        .catch(() => {
          this.copyLoading = false;
        });
    },
    handleClose() {
      this.dialogVisible = false;
      this.currRow.select = '';
    },
    handleDbl(row) {
      this.currRow = row;
      this.addVisible = true;
    },
    handleChange(e, row) {
      // 编辑
      this.currRow = row;
      console.log(row)
      if (e === 1) {
        this.addVisible = true;
        row.select = '';
      }
      // 删除
      if (e === 2) {
        this.$confirm('确定要删除该模板吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            templateDelReq({
              print_template_local_id: row.print_template_local_id,
            })
              .then((res) => {
                if (res.code === 1) {
                  this.$message.success('删除成功');
                  this.getList();
                }
              })
              .catch((err) => {
                this.$message.error(err.msg);
              });
          })
          .catch(() => {
            row.select = '';
          });
      }
      // 复制
      if (e === 3) {
        this.addForm = {
          template_name: row.template_name + '-副本',
        };
        this.dialogVisible = true;
        this.getMerchantList()
      }
    },
    handleIndex({row, rowIndex}) {
      row.index = rowIndex + 1;
    },
    // 创建人
    getEmployee() {
      getEmployeeReq()
        .then((res) => {
          if (res.code === 1) {
            this.employeeList = res.data;
          }
        });
    },
    // 模板类型
    getCustomTemplateType() {
      getCustomTemplateTypeReq()
        .then((res) => {
          if (res.code === 1) {
            this.typeList = res.data;
          }
        });
    },
    // 列表
    getList() {
      this.loading = true;
      getCustomTemplateListReq({
        page: this.page,
        limit: this.limit,
        ...this.form,
      })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    handleAdd() {
      this.currRow = {};
      this.addVisible = true;
    },
    handleSizeChange(limit) {
      this.page = 1;
      this.limit = limit;
      this.getList();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .form .el-form-item {
  margin-bottom: 0px !important;
}
</style>
