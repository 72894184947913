<template>
  <div>
    <!-- <el-button type="primary" @click="Design1">打印设计</el-button> -->
    <!-- <param name="Caption" value="我是打印控件lodop"> -->
    <div class="top">
      <div style="cursor: pointer;" @click="handleBack">
        <i class="el-icon-back" style="font-size: 18px; margin-right: 5px;"></i>返回
      </div>
      <div class="line" style="height: 30px;"></div>
      <el-form ref="form" :rules="rules" :model="form" :inline="true">
        <el-form-item label="打印模板名称" required="" prop="template_name">
          <el-input v-model="form.template_name" placeholder="请输入打印模板名称"></el-input>
        </el-form-item>
        <el-form-item label="尺寸" required="" prop="size" label-width="80px">
          <div class="size">
            <el-input
              v-model="form.width"
              placeholder=""
              style="width: 100px"
              @input="handleSize"
            ></el-input>
            <span style="margin: 0 10px">×</span>
            <el-input
              v-model="form.height"
              placeholder=""
              style="width: 100px"
              @input="handleSize"
            ></el-input>
            <span style="margin-left: 10px">毫米</span>
          </div>
        </el-form-item>
        <el-form-item label="模板类型" required="" label-width="100px">
          <el-select v-model="form.type" placeholder="请选择" @change="handleChangeSelect">
            <el-option
              v-for="item in typeList"
              :key="item.type"
              :label="item.name"
              :value="item.type"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="向下偏移(毫米)" v-if="form.type !== 50">
          <el-input v-model="form.offset" type="number" style="width: 80px;"></el-input>
        </el-form-item>
        <el-form-item label="上间隙(毫米)" v-if="form.type === 50">
          <el-input v-model="form.gap" type="number" style="width: 80px;"></el-input>
        </el-form-item>
        <el-form-item v-show="!form.picpath && form.size">
          <div style="display: flex; align-items: center;">
            <!-- <el-button type="primary" size="small" style="height: 32px;" @click="handleAdd">整体放大</el-button>
            <el-button type="primary" size="small" style="height: 32px; margin-right: 10px;" @click="handleReduce">整体缩小</el-button> -->
            <el-upload
              ref="my-upload"
              :limit="1"
              :show-file-list="false"
              :action=" baseUrl + '/store/goods.PrintLocalTemplate/uploadBackground'"
              :headers="{
                authorization: token,
              }"
              :on-change="handleChange"
              :on-exceed="handleExceed"
              :on-success="handleImgSuccess"
            >
              <el-button size="small" type="primary">设置背景图</el-button>
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item v-if="!form.size && !form.picpath">
          <el-button size="small" type="primary" @click="handleSetBg"
            >设置背景图</el-button
          >
        </el-form-item>
        <el-form-item style="justify-items: flex-end">
          <el-button size="small" @click="clearBg" v-if="form.picpath"
            >清空背景图</el-button
          >
          <!-- <el-button size="small" type="primary" @click="handlePrint"
            >预览</el-button
          > -->
          <el-button size="small" icon="el-icon-zoom-in" @click="handleBig">放大</el-button>
          <el-button size="small" icon="el-icon-zoom-out" @click="handleSmall">缩小</el-button>
          <el-button size="small" type="primary" @click="handleSave">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="wrapper">
      <div class="left" v-loading="loading">
        <el-input placeholder="搜索" v-model="title" @keydown.enter.native="getCustomTemplateFields" >
          <i class="el-icon-search el-input__icon" slot="suffix" @click="getCustomTemplateFields"></i>
        </el-input>
        <el-collapse v-model="activeName" accordion style="margin-top: 10px">
          <template v-for="item in infoList">
            <el-collapse-item
              :title="item.title"
              :name="item.field_alias"
              :key="item.field_alias"
            >
              <el-checkbox-group v-model="checkList" v-if="item.children">
                <template v-for="check in item.children">
                  <el-checkbox
                    @change="onCheckBox(check.field_alias)"
                    :label="check.field_alias"
                    :key="check.field_alias"
                    :disabled="check.field_alias === 'other_table2' ? true : false"
                    >{{ check.title }}</el-checkbox
                  >
                </template>
              </el-checkbox-group>
              <div style="color: #ccc; margin-left: 5px;" v-if="!item.children.length">暂无信息</div>
            </el-collapse-item>
          </template>
        </el-collapse>
      </div>
      <!-- 展示给用户看的 单位px -->
      <div class="middle" id="font_content">
        <div
          id="px_content"
          :style="{
            width: form.width + 'mm',
            height: (Number(form.height) + Number(form.gap))+ 'mm',
            background: 'white',
            backgroundImage: 'url(' + form.picpath + ')',
            backgroundSize: '100% 100%',
            position: 'relative',
            top: 0 + 'px',
            left: 0 + 'px',
            transformOrigin: '0 0',
            transform: 'scale(' + multiple + ')',
          }"
        >
          <div :style="{
            width: form.width + 'mm',
            height: form.gap + 'mm',
            borderBottom: Number(form.gap) ? '1px dashed #ccc' : '',
            background: 'white',
            transformOrigin: '0 0',
        }"></div>
          <template v-for="item in itemList">
            <!-- 一般变量 -->
            <div
              v-if="
                item.field_alias.indexOf('other_table') === -1 &&
                item.field_alias !== 'other_code' && item.field_alias !== 'other_qrcode'
              "
              :data-key="item.field_alias"
              :style="{
                position: 'absolute',
                left: item.positionX + 'px',
                top: item.positionY + 'px',
                zIndex: zIndex,
                width: item.width + 'mm',
                height: item.height  + 'mm',
                border: '1px solid #ddd',
                backgroundColor:
                  item.field_alias === itemForm.field_alias && !itemForm.key
                    ? 'rgba(0, 0, 170, .1)'
                    : '',
                color: 'black',
                cursor: 'default',
                fontSize: item.fontSize * ratio + 'px',
                transform: 'rotate(' + item.rotate + 'deg)',
                userSelect: 'none',
                overflow: 'hidden',
                textAlign: item.textAlign,
                boxSizing: 'border-box',
                transformOrigin: '0 0',
                fontFamily: item.fontFamily,
              }"
              :key="item.field_alias"
              @mousedown.self="handleMove($event, item.field_alias)"
            >
              <div
                v-if="item.field_alias.indexOf('other_txt') === -1"
                @mousedown="handleMove($event, item.field_alias, 1)"
                :style="{
                  transformOrigin: ' 0 0',
                  transform:
                    item.fontSize < 12
                      ? 'scale(' + item.fontSize / 12  + ')'
                      : '',
                  width: item.fontSize < 12 ? item.width * (12 / item.fontSize) + 'mm': item.width + 'mm',
                }"
              >
                {{item.fontTxt}}{{ item.font_name }}{{item.backTxt}}
              </div>
              <div
                v-else
                @mousedown="handleMove($event, item.field_alias, 1)"
                :style="{
                  transformOrigin: '0 0',
                  transform:
                    item.fontSize < 12
                      ? 'scale(' + item.fontSize / 12 + ')'
                      : '',
                  width: item.fontSize < 12 ? item.width * (12 / item.fontSize) + 'mm': item.width  + 'mm',
                }"
              >
                {{ item.title }}
              </div>
              <div
                :style="{
                  position: 'absolute',
                  width: '8px',
                  height: '8px',
                  background: 'url(' + icon + ')',
                  backgroundSize: '100% 100%',
                  cursor: (item.rotate === '0' || item.rotate === '180' || item.rotate === '360') ? 'nw-resize' : (item.rotate === '90' || item.rotate === '270') ? 'ne-resize' : '',
                  bottom: '0px',
                  right: '0px',
                }"
                @mousedown.self="changeSize($event, item.field_alias)"
              ></div>
            </div>
            <!-- 表格 -->
            <table
              :key="item.field_alias"
              @mousedown="handleMove($event, item.field_alias, 2)"
              v-if="item.field_alias.indexOf('other_table') > -1"
              cellspacing="0"
              :style="{
                border: item.lineShow ? '1px solid black' : '',
                borderWidth: item.lineShow ? '1px 0 0 1px' : '',
                userSelect: 'none',
                cursor: 'default',
                position: 'absolute',
                left: row.print_template_local_id ? item.positionX + 'px' : '',
                top: row.print_template_local_id? item.positionY + 'px' : '',
                fontFamily: item.fontFamily,
                backgroundColor:
                  item.field_alias === itemForm.field_alias
                    ? 'rgba(0, 0, 170, .1)'
                    : '',
              }"
            >
              <tr v-if="item.titleShow">
                <th
                  v-for="data in item.titles"
                  :key="data.key"
                  @dblclick="handleTabelCurr(data.key, 'titles', item.field_alias)"
                  :style="{
                    border: item.lineShow ? '1px solid black' : '',
                    borderWidth: item.lineShow ? '0 1px 1px 0' : '',
                    fontWeight: 'bold',
                    width: data.width + 'mm',
                    height: data.height + 'mm',
                    fontSize: data.fontSize * ratio + 'px',
                    textAlign: data.textAlign,
                    backgroundColor:
                      data.key === itemForm.key ? 'rgba(0, 0, 170, .1)' : '',
                  }"
                >
                  {{ data.title }}
                </th>
                <th
                  :style="{
                    border: item.lineShow ? '1px solid black' : '',
                    borderWidth: item.lineShow ? '0 1px 1px 0' : '',
                  }"
                ></th>
              </tr>
              <tr>
                <td
                  @dblclick="handleTabelCurr(column.key, 'column', item.field_alias)"
                  v-for="column in item.column"
                  :key="column.key"
                  :style="{
                    border: item.lineShow ? '1px solid black' : '',
                    borderWidth: item.lineShow ? '0 1px 1px 0' : '',
                    width: column.width + 'mm',
                    height: column.height + 'mm',
                    fontSize: column.fontSize * ratio + 'px',
                    backgroundColor:
                      column.key === itemForm.key ? 'rgba(0, 0, 170, .1)' : '',
                    textAlign: column.textAlign,
                  }"
                >
                  <template v-if="!column.font_name && !column.back_name">{{
                    column.title
                  }}</template>
                  <template v-if="column.font_name">{{
                    column.font_name
                  }}</template>
                </td>
                <td
                  :style="{
                    border: item.lineShow ? '1px solid black' : '',
                    borderWidth: item.lineShow ? '0 1px 1px 0' : '',
                  }"
                >
                  <span
                    :style="{
                      color: '#409eff',
                      cursor: 'pointer',
                      fontSize: 12 * ratio + 'px'
                    }"
                    @click="handleAddColumns(item.field_alias)"
                  >
                    增加列
                  </span>
                  <span
                    :style="{
                      color: '#409eff',
                      cursor: 'pointer',
                      fontSize: 12 * ratio + 'px'
                    }"
                    @click="handleDeleColumns(item.field_alias)"
                    >删除列</span
                  >
                </td>
              </tr>
            </table>
            <!-- 一维码 -->
            <div :key="item.field_alias"
                id="code"
                :style="{
                  transform: 'rotate(' + item.rotate + 'deg)',
                  position: 'absolute',
                  left: item.positionX + 'px',
                  top: item.positionY + 'px',
                  userSelect: 'none',
                  cursor: 'default',
                  height: item.height + 'mm',
                  width: item.width  + 'mm',
                }"
                v-if="item.field_alias === 'other_code'">
              <!-- <img
                v-if="item.barcode"
                :src="'data:image/svg+xml;base64,' + item.barcode"
                style="width: 100%; height: 100%;"
                @mousedown="handleMove($event, item.field_alias, 1)"
              /> -->
              <img
                :src="code"
                :style="{
                  height: item.height  + 'mm',
                  width: item.width + 'mm',
                }"
                @mousedown="handleMove($event, item.field_alias, 1)"
              />
              <div
                :style="{
                  position: 'absolute',
                  width: '8px',
                  height: '8px',
                  background: 'url(' + icon + ')',
                  backgroundSize: '100% 100%',
                  bottom: '0px',
                  right: '0px',
                  cursor: 'nw-resize',
                }"
                @mousedown.self="changeSize($event, item.field_alias)"
              ></div>
            </div>
            <!-- 二维码 -->
            <div :key="item.field_alias"
                id="qrcode"
                :style="{
                  transform: 'rotate(' + item.rotate + 'deg)',
                  position: 'absolute',
                  left: item.positionX + 'px',
                  top: item.positionY + 'px',
                  userSelect: 'none',
                  cursor: 'default',
                  height: item.height + 'mm',
                  width: item.width  + 'mm',
                }"
                v-if="item.field_alias === 'other_qrcode'">
              <!-- <img
                v-if="item.barcode"
                :src="'data:image/svg+xml;base64,' + item.barcode"
                style="width: 100%; height: 100%;"
                @mousedown="handleMove($event, item.field_alias, 1)"
              /> -->
              <img
                :src="qrcode"
                :style="{
                  height: item.height  + 'mm',
                  width: item.width + 'mm',
                }"
                @mousedown="handleMove($event, item.field_alias, 1)"
              />
              <div
                :style="{
                  position: 'absolute',
                  width: '8px',
                  height: '8px',
                  background: 'url(' + icon + ')',
                  backgroundSize: '100% 100%',
                  bottom: '0px',
                  right: '0px',
                  cursor: 'nw-resize',
                }"
                @mousedown.self="changeSize($event, item.field_alias)"
              ></div>
            </div>
          </template>
        </div>
      </div>
      <!-- 传给后端的dom结构 单位mm -->
      <div class="middle" id="content" style="position: absolute; visibility: hidden;">
        <div style="font-family: 'Microsoft YaHei'">
          <template v-if="form.type === 50">{{label_font}}</template>
          <div :style="{ width: form.width + 'mm', height: form.gap + 'mm' }"></div>
          <div
            id="mm_content"
            :style="{
              width: form.width + 'mm',
              height: form.height + 'mm',
              background: 'white',
              position: 'relative',
            }"
          >
            <template v-for="item in itemList">
              <!-- 一般变量 -->
              <div
                v-if="
                  item.field_alias.indexOf('other_table') === -1 &&
                  item.field_alias !== 'other_code'
                "
                :data-key="item.field_alias"
                :style="{
                  position: 'absolute',
                  left: item.positionX / mul + 'mm',
                  top: item.positionY / mul + 'mm',
                  zIndex: zIndex,
                  width: item.width + 'mm',
                  height: item.height + 'mm',
                  color: 'black',
                  cursor: 'default',
                  fontSize: item.fontSize + 'px',
                  transform: 'rotate(' + item.rotate + 'deg)',
                  userSelect: 'none',
                  overflow: 'hidden',
                  textAlign: item.textAlign,
                }"
                :key="item.field_alias"
              >
                <div
                  v-if="item.field_alias.indexOf('other_txt') === -1"
                  :style="{
                    transformOrigin: ' 0 0',
                    transform:
                      item.fontSize < 12
                        ? 'scale(' + item.fontSize / 12 + ')'
                        : '',
                    width: item.fontSize < 12 ? item.width * (12 / item.fontSize) + 'mm': item.width  + 'mm',
                  }"
                >
                <template v-if="item.field_alias === 'page_index'">{{page_font_index}}</template>
                <template v-if="item.field_alias === 'page_amount_dx'">{{page_font_amount_dx}}</template>
                <template v-if="item.field_alias === 'page_amount'">{{page_font_amount}}</template>
                  {{ item.back_name }}
                <template v-if="item.field_alias === 'page_index'">{{page_back_index}}</template>
                <template v-if="item.field_alias === 'page_amount_dx'">{{page_back_amount_dx}}</template>
                <template v-if="item.field_alias === 'page_amount'">{{page_back_amount}}</template>
                </div>
                <div
                  v-else
                  :style="{
                    transformOrigin: '0 0',
                    transform:
                      item.fontSize < 12
                        ? 'scale(' + item.fontSize / 12 + ')'
                        : '',
                    width: item.fontSize < 12 ? item.width * (12 / item.fontSize) + 'mm': item.width + 'mm',
                  }"
                >
                  {{ item.title }}
                </div>
                <div
                  :style="{
                    position: 'absolute',
                    width: '8px',
                    height: '8px',
                    bottom: '0px',
                    right: '0px',
                    cursor: 'nw-resize',
                  }"
                ></div>
              </div>
              <!-- 表格 -->
              <div :id="item.field_alias"  v-if="item.field_alias.indexOf('other_table') > -1"
                :key="item.field_alias">
              <table
                v-if="item.field_alias.indexOf('other_table') > -1"
                cellspacing="0"
                :style="{
                  border: item.lineShow ? '1px solid black' : '',
                  borderWidth: item.lineShow ? '1px 0 0 1px' : '',
                  userSelect: 'none',
                  cursor: 'default',
                }"
              >
                <tr v-if="item.titleShow">
                  <th
                    v-for="data in item.titles"
                    :key="data.key"
                    :style="{
                      border: item.lineShow ? '1px solid black' : '',
                      borderWidth: item.lineShow ? '0 1px 1px 0' : '',
                      fontWeight: 'bold',
                      width: data.width  + 'mm',
                      height: data.height  + 'mm',
                      fontSize: data.fontSize  + 'px',
                      textAlign: data.textAlign,
                    }"
                  >
                    {{ data.title }}
                  </th>
                  <!-- <th
                    :style="{
                      border: item.lineShow ? '1px solid black' : '',
                      borderWidth: item.lineShow ? '0 1px 1px 0' : '',
                    }"
                  ></th> -->
                </tr>
                {{item.font}}
                <tr>
                  <td
                    v-for="column in item.column"
                    :key="column.key"
                    :style="{
                      border: item.lineShow ? '1px solid black' : '',
                      borderWidth: item.lineShow ? '0 1px 1px 0' : '',
                      width: column.width  + 'mm',
                      height: column.height  + 'mm',
                      fontSize: column.fontSize + 'px',
                      textAlign: column.textAlign,
                    }"
                  >
                    <template v-if="!column.font_name && !column.back_name">{{
                      column.title
                    }}</template>
                    <template v-if="column.font_name">{{
                      column.back_name
                    }}</template>
                  </td>
                  <!-- <td
                    :style="{
                      border: item.lineShow ? '1px solid black' : '',
                      borderWidth: item.lineShow ? '0 1px 1px 0' : '',
                    }"
                  >
                    <span
                      style="color: #409eff; cursor: pointer; font-size: 12px"
                      @click="handleAddColumns('other_table')"
                    >
                      增加列
                    </span>
                    <span
                      style="color: #409eff; cursor: pointer; font-size: 12px"
                      @click="handleDeleColumns('other_table')"
                      >删除列</span
                    >
                  </td> -->
                </tr>
                {{item.back}}
              </table>
              </div>
              <!-- 一维码 -->
              <!-- width: item.width + 'mm',
              height: item.height + 'mm', -->
              <img
                draggable="true"
                :key="item.field_alias"
                v-if="item.field_alias === 'other_code'"
                src="data:image/svg+xml;base64,${barcode}"
                :style="{
                  transform: 'rotate(' + item.rotate + 'deg)',
                  position: 'absolute',
                  left: item.positionX / mul + 'mm',
                  top: item.positionY / mul + 'mm',
                  userSelect: 'none',
                  cursor: 'default',
                }"
              />
            </template>
          </div>
          <template v-if="form.type === 50">{{label_back}}</template>
        </div>
      </div>
      <template v-for="item in itemList">
        <!-- 非表格列的 -->
        <div
          class="right"
          :key="item.field_alias"
          v-if="item.field_alias === itemForm.field_alias && !itemForm.key"
        >
          <el-form :model="itemForm" label-width="85px">
            <el-form-item label="当前目标">
              <el-tag>{{item.label}}</el-tag>
            </el-form-item>
            <el-form-item label="宽" v-if="item.field_alias.indexOf('other_table') === -1">
              <div style="display: flex">
                <el-input
                  v-model="itemForm.width"
                  style="width: 100px; margin-right: 10px"
                ></el-input>
                <div>毫米</div>
              </div>
            </el-form-item>
            <el-form-item label="高" v-if="item.field_alias.indexOf('other_table') === -1">
              <div style="display: flex">
                <el-input
                  v-model="itemForm.height"
                  style="width: 100px; margin-right: 10px"
                ></el-input>
                <div>毫米</div>
              </div>
            </el-form-item>
            <el-form-item label="货号" v-if="item.field_alias.indexOf('other_code') !== -1">
               <el-radio-group v-model="item.showNumber">
                <el-radio :label="1">显示</el-radio>
                <el-radio :label="0">隐藏</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="旋转"
              v-if="item.field_alias.indexOf('other_table') === -1"
            >
              <!-- <div style="display: flex">
                <el-input
                  v-model="itemForm.rotate"
                  style="width: 100px; margin-right: 10px"
                ></el-input>
                <div>°</div>
              </div> -->
              <el-select v-model="itemForm.rotate" style="width: 100px;">
                <template v-for="item in rotateList">
                  <el-option :key="item" :label="item + '°'" :value="item"></el-option>
                </template>
              </el-select>
            </el-form-item>
            <!-- <el-form-item
              label="条码类型"
              v-if="item.field_alias.indexOf('other_code') > -1"
            >
              <el-select v-model="itemForm.barcode_type" @change="getGoodsBarcode">
                <template v-for="item in barcodeType">
                  <el-option :key="item.type_id" :label="item.name" :value="item.type_id"></el-option>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item
              label="条宽比"
              v-if="item.field_alias.indexOf('other_code') > -1"
            >
              <el-select v-model="itemForm.width_factor" @change="getGoodsBarcode">
                <template v-for="item in 30">
                  <el-option :key="item" :label="item" :value="item"></el-option>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item
              label="条码位数"
              v-if="item.field_alias.indexOf('other_code') > -1"
            >
              <el-input placeholder="请输入正整数" v-model="itemForm.number_digit" @input="handleCodeInput($event, 'number_digit')"></el-input>
            </el-form-item> -->
            <el-form-item
              label="字号"
              v-if="
                item.field_alias.indexOf('other_table') === -1 &&
                item.field_alias !== 'other_code' && item.field_alias !== 'other_qrcode'
              "
            >
              <div style="display: flex">
                <!-- <el-input
                  v-model="itemForm.fontSize"
                  style="width: 100px; margin-right: 10px"
                ></el-input> -->
                <el-select
                  v-model="itemForm.fontSize"
                  placeholder="请选择"
                  style="width: 100px"
                >
                  <el-option
                    v-for="item in fontSizeList"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
            <!-- <el-form-item
              label="行高"
              v-if="
                item.field_alias.indexOf('other_table') === -1 &&
                item.field_alias !== 'other_code'
              "
            >
              <div style="display: flex">
                <el-input
                  v-model="itemForm.lineHeight"
                  style="width: 100px; margin-right: 10px"
                ></el-input>
                <div>毫米</div>
              </div>
            </el-form-item> -->
            <el-form-item
              v-if="item.field_alias.indexOf('other_code') > -1 || (item.field_alias.indexOf('other_table') === -1 && item.field_alias !== 'other_qrcode')"
              label="文本对齐">
              <el-select v-model="itemForm.textAlign" style="width: 100px;">
                <el-option v-if="item.field_alias.indexOf('other_code') > -1" label="坐标定位" value="coordinates"></el-option>
                <el-option v-if="item.field_alias.indexOf('other_code') == -1" label="左" value="left"></el-option>
                <el-option v-if="item.field_alias.indexOf('other_code') == -1" label="居中" value="center"></el-option>
                <el-option v-if="item.field_alias.indexOf('other_code') > -1" label="平铺" value="center"></el-option>
                <el-option v-if="item.field_alias.indexOf('other_code') == -1" label="右" value="right"></el-option>
              </el-select>
             </el-form-item>
             <el-form-item label="字体" v-if="item.field_alias.indexOf('other_code') === -1 && item.field_alias !== 'other_qrcode'">
              <el-select v-model="itemForm.fontFamily" style="width: 120px;">
                <template v-for="item in fontFamilyList">
                  <el-option :label="item" :value="item" :key="item"></el-option>
                </template>
              </el-select>
            </el-form-item>
            <el-form-item label="字重" v-if="item.field_alias.indexOf('other_code') === -1 && item.field_alias !== 'other_qrcode'">
              <el-select v-model="itemForm.bold" style="width: 120px;">
                  <el-option label="正常" :value="0"></el-option>
                  <el-option label="加粗" :value="1"></el-option>
              </el-select>
            </el-form-item>
             <el-form-item
              label="自定义文字前缀"
              v-if="item.field_alias.indexOf('other_txt') === -1 && item.field_alias.indexOf('other_table') === -1 && item.field_alias.indexOf('other_code') === -1 && item.field_alias !== 'other_qrcode'"
            >
              <div style="display: flex">
                <el-input
                  type="textarea"
                  rows="4"
                  v-model="itemForm.fontTxt"
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item
              label="自定义文字后缀"
              v-if="item.field_alias.indexOf('other_txt') === -1 && item.field_alias.indexOf('other_table') === -1 && item.field_alias.indexOf('other_code') === -1 && item.field_alias !== 'other_qrcode'"
            >
              <div style="display: flex">
                <el-input
                  type="textarea"
                  rows="4"
                  v-model="itemForm.backTxt"
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item
              label="自定义文字"
              v-if="item.field_alias.indexOf('other_txt') > -1"
            >
              <div style="display: flex">
                <el-input
                  type="textarea"
                  rows="4"
                  v-model="itemForm.title"
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item
              label="插入变量"
              v-if="item.field_alias.indexOf('other_qrcode') > -1"
            >
              <el-select v-model="qrCodeValue" @change="handleQrCodeValue($event, item)">
                <el-option v-for="item in qrCodeValueList" :key="item.field_alias" :value="item.field_alias" :label="item.title"></el-option>
              </el-select>
            </el-form-item>
            <div>
            <!-- 二维码内容 -->
            <el-input type="textarea" :rows="3" v-model="item.value" id="qrInput" placeholder="表达式" />
            </div>
            <el-form-item
              label="表格边框"
              v-if="item.field_alias.indexOf('other_table') > -1"
            >
              <el-radio-group v-model="itemForm.lineShow">
                <el-radio :label="0">隐藏</el-radio>
                <el-radio :label="1">显示</el-radio>
              </el-radio-group>
              <!-- <el-radio-group v-model="itemForm.lineStyle">
                <el-radio :label="0">实线</el-radio>
                <el-radio :label="1">虚线</el-radio>
              </el-radio-group> -->
            </el-form-item>
            <el-form-item
              label="表头"
              v-if="item.field_alias.indexOf('other_table') > -1"
            >
              <el-radio-group v-model="itemForm.titleShow">
                <el-radio :label="0">隐藏</el-radio>
                <el-radio :label="1">显示</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="分页条数"
              v-if="item.field_alias.indexOf('other_table') > -1"
            >
              <el-radio-group v-model="itemForm.isPage">
                <el-radio :label="0"
                  >限制每页<el-input
                    v-model="itemForm.pageSize"
                    style="width: 50px; margin: 0 10px"
                  ></el-input
                  >条</el-radio
                >
                <!-- <el-radio :label="1">不限制</el-radio> -->
              </el-radio-group>
            </el-form-item>
            <el-form-item label="旧料分开打印" v-if="item.field_alias === 'other_table1' && form.type !== 40">
              <el-switch
                @change="handleSwitch"
                v-model="form.is_separated"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
            </el-form-item>
          </el-form>
        </div>
        <!-- 表格的标题 -->
        <template v-for="data in item.titles">
          <div
            class="right"
            :key="data.key"
            v-if="data.key === itemForm.key && data.key.indexOf('title') > -1"
          >
            <el-form :model="itemForm" label-width="70px">
              <el-form-item label="当前目标">
                <el-tag>{{item.title}}标题</el-tag>
              </el-form-item>
              <el-form-item label="宽">
                <div style="display: flex">
                  <el-input
                    v-model="itemForm.width"
                    style="width: 100px; margin-right: 10px"
                  ></el-input>
                  <div>毫米</div>
                </div>
              </el-form-item>
              <el-form-item label="高">
                <div style="display: flex">
                  <el-input
                    v-model="itemForm.height"
                    style="width: 100px; margin-right: 10px"
                  ></el-input>
                  <div>毫米</div>
                </div>
              </el-form-item>
              <!-- <el-form-item label="旋转">
                <div style="display: flex">
                  <el-input
                    v-model="itemForm.rotate"
                    style="width: 100px; margin-right: 10px"
                  ></el-input>
                  <div>°</div>
                </div>
                <el-select v-model="itemForm.rotate" style="width: 100px;">
                  <template v-for="item in rotateList">
                    <el-option :key="item" :label="item + '°'" :value="item"></el-option>
                  </template>
                </el-select>
              </el-form-item> -->
              <el-form-item label="字号">
                <div style="display: flex">
                  <!-- <el-input
                    v-model="itemForm.fontSize"
                    style="width: 100px; margin-right: 10px"
                  ></el-input> -->
                  <el-select
                  v-model="itemForm.fontSize"
                  placeholder="请选择"
                  style="width: 100px"
                >
                  <el-option
                    v-for="item in fontSizeList"
                    :key="item"
                    :label="item"
                    :value="item"
                    v-if="item >= 12"
                  >
                  </el-option>
                </el-select>
                </div>
              </el-form-item>
              <!-- <el-form-item label="行高">
                <div style="display: flex">
                  <el-input
                    v-model="itemForm.lineHeight"
                    style="width: 100px; margin-right: 10px"
                  ></el-input>
                  <div>毫米</div>
                </div>
              </el-form-item> -->
              <el-form-item
                label="文本对齐">
                <el-select v-model="itemForm.textAlign" style="width: 100px;">
                  <el-option label="左" value="left"></el-option>
                  <el-option label="中" value="center"></el-option>
                  <el-option label="右" value="right"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="表头文字">
                <div style="display: flex">
                  <el-input
                    type="textarea"
                    rows="4"
                    v-model="itemForm.title"
                  ></el-input>
                </div>
              </el-form-item>
              <!-- <el-form-item label="线段">
                <el-radio-group v-model="itemForm.lineShow">
                  <el-radio :label="0">隐藏</el-radio>
                  <el-radio :label="1">显示</el-radio>
                </el-radio-group>
                <el-radio-group v-model="itemForm.lineStyle">
                  <el-radio :label="0">实线</el-radio>
                  <el-radio :label="1">虚线</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="信息标题">
                <el-radio-group v-model="itemForm.titleShow">
                  <el-radio :label="0">隐藏</el-radio>
                  <el-radio :label="1">显示</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="分页条数">
                <el-radio-group v-model="itemForm.pageSize">
                  <el-radio :label="0">限制每页5条</el-radio>
                  <el-radio :label="1">不限制</el-radio>
                </el-radio-group>
              </el-form-item> -->
            </el-form>
          </div>
        </template>
        <!-- 表格的列 -->
        <template v-for="data in item.column">
          <div
            class="right"
            :key="data.key"
            v-if="data.key === itemForm.key && data.key.indexOf('column') > -1"
          >
            <el-form :model="itemForm" label-width="70px">
              <el-form-item label="当前目标">
                <el-tag>{{item.title}}列</el-tag>
              </el-form-item>
              <el-form-item label="数据源">
                <el-select
                  v-model="itemForm.field_alias"
                  placeholder="请选择"
                  style="width: 150px"
                  filterable
                >
                  <template v-for="item in sourceData[item.field_alias]">
                    <el-option
                      :key="item.field_alias"
                      :label="item.title"
                      :value="item.field_alias"
                      @click.native="handleSelect(item, data)"
                    >
                    </el-option>
                  </template>
                </el-select>
              </el-form-item>
              <el-form-item label="宽">
                <div style="display: flex">
                  <el-input
                    v-model="itemForm.width"
                    style="width: 100px; margin-right: 10px"
                  ></el-input>
                  <div>毫米</div>
                </div>
              </el-form-item>
              <el-form-item label="高">
                <div style="display: flex">
                  <el-input
                    v-model="itemForm.height"
                    style="width: 100px; margin-right: 10px"
                  ></el-input>
                  <div>毫米</div>
                </div>
              </el-form-item>
              <!-- <el-form-item label="旋转">
                <div style="display: flex">
                  <el-input
                    v-model="itemForm.rotate"
                    style="width: 100px; margin-right: 10px"
                  ></el-input>
                  <div>°</div>
                </div>
                <el-select v-model="itemForm.rotate" style="width: 100px;">
                  <template v-for="item in rotateList">
                    <el-option :key="item" :label="item + '°'" :value="item"></el-option>
                  </template>
                </el-select>
              </el-form-item> -->
              <el-form-item label="字号">
                <div style="display: flex">
                  <!-- <el-input
                    v-model="itemForm.fontSize"
                    style="width: 100px; margin-right: 10px"
                  ></el-input> -->
                  <el-select
                  v-model="itemForm.fontSize"
                  placeholder="请选择"
                  style="width: 100px"
                >
                  <el-option
                    v-for="item in fontSizeList"
                    :key="item"
                    :label="item"
                    :value="item"
                    v-if="item >= 12"
                  >
                  </el-option>
                </el-select>
                </div>
              </el-form-item>
              <!-- <el-form-item label="行高">
                <div style="display: flex">
                  <el-input
                    v-model="itemForm.lineHeight"
                    style="width: 100px; margin-right: 10px"
                  ></el-input>
                  <div>毫米</div>
                </div>
              </el-form-item> -->
              <el-form-item
                label="文本对齐">
                <el-select v-model="itemForm.textAlign" style="width: 100px;">
                  <el-option label="左" value="left"></el-option>
                  <el-option label="中" value="center"></el-option>
                  <el-option label="右" value="right"></el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item label="自定义文字">
                <div style="display: flex">
                  <el-input
                    type="textarea"
                    rows="4"
                    v-model="itemForm.title"
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item label="线段">
                <el-radio-group v-model="itemForm.lineShow">
                  <el-radio :label="0">隐藏</el-radio>
                  <el-radio :label="1">显示</el-radio>
                </el-radio-group>
                <el-radio-group v-model="itemForm.lineStyle">
                  <el-radio :label="0">实线</el-radio>
                  <el-radio :label="1">虚线</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="信息标题">
                <el-radio-group v-model="itemForm.titleShow">
                  <el-radio :label="0">隐藏</el-radio>
                  <el-radio :label="1">显示</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="分页条数">
                <el-radio-group v-model="itemForm.pageSize">
                  <el-radio :label="0">限制每页5条</el-radio>
                  <el-radio :label="1">不限制</el-radio>
                </el-radio-group>
              </el-form-item> -->
            </el-form>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>
<script>

import Cookies from "js-cookie";
import storage from "good-storage";
import numeral from 'numeral';
import { baseUrl } from '@/utils/request';
import { getCustomTemplateFieldsReq, getCustomTemplateTypeReq, createCustomTemplateReq, getCustomTemplateInfoReq, updateCustomTemplateReq, getTemplateGoodsListReq, getBarcodeTypeReq, getGoodsBarcodeReq } from '@/api/system/print/customPrint';

export default {
  name: "CustomPrint",
  data() {
    return {
      qrCodeValue: '',
      qrCodeValueList: [], // 组成二维码的变量
      fontFamilyList: ['微软雅黑','微软雅黑 Light', '仿宋', '黑体', '楷体', '宋体', '新宋体',],
      rotateList: ['0', '90', '180', '270', '360'],
      list: [
        {
          field_alias: 'goods_number',
          left: 10,
          top: 10,
          width: 20,
          height: 100,
          type: 'text',
        },
        {
          field_alias: 'other_code',
          left: 10,
          top: 10,
          width: 20,
          height: 100,
          type: 'code',
        },
        {
          field_alias: 'other_table',
          left: 10,
          top: 10,
          width: 20,
          height: 100,
          type: 'table',
          html: '<table>${good_name}</table>',
        },
      ],
      multiple: 1, // 整体放大倍数
      timer2: null,
      baseUrl: '',
      token: '',
      timer: null,
      sourceData: {}, // 表格数据源
      infoObj: {},
      isChecked: [],
      page_font_amount: '<w:p>${page_set_list_amount}</w:p>',
      page_back_amount: '<w:p>${/page_set_list_amount}</w:p>',
      page_font_amount_dx: '<w:p>${page_set_list_amount_dx}</w:p>',
      page_back_amount_dx: '<w:p>${/page_set_list_amount_dx}</w:p>',
      page_font_index: '<w:p>${page_set_list_index}</w:p>',
      page_back_index: '<w:p>${/page_set_list_index}</w:p>',
      label_font: '<w:p>${goodsList}</w:p>',
      label_back: '<w:p>${/goodsList}</w:p>',
      loading: false,
      title: '',
      fontSizeList: [
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '20',
        '21',
        '22',
        '23',
        '24',
        '25',
        '26',
        '27',
        '28',
        '29',
        '30',
      ],
      mul: 4, // 倍率，需要将mm转换成px的自定义倍率;
      icon: require("@/assets/images/label-icon.png"),
      code: require("@/assets/images/label-code.png"),
      qrcode: require("@/assets/images/qrcode.png"),
      itemList: [], // 每个变量的列表
      zIndex: 1,
      positionX: 0,
      positionY: 0,
      bgUrl: "",
      itemForm: {}, // 每个选项的表单
      checkList: [],
      infoList: [],
      // infoList: [
      //   {
      //     title: "订单信息",
      //     field_alias: "order",
      //     children: [
      //       {
      //         title: "标签价",
      //         field_alias: "sale_price",
      //       },
      //       {
      //         title: "数量",
      //         field_alias: "num",
      //       },
      //     ],
      //   },
      //   {
      //     title: "货品信息",
      //     field_alias: "goods",
      //     children: [
      //       {
      //         title: "货号",
      //         field_alias: "good_number",
      //       },
      //     ],
      //   },
      //   {
      //     title: "其他",
      //     field_alias: "other",
      //     children: [
      //       {
      //         title: "销售货品列表",
      //         field_alias: "other_table1",
      //       },
      //       {
      //         title: "自定义文字1",
      //         field_alias: "other_txt1",
      //       },
      //       {
      //         title: "自定义文字2",
      //         field_alias: "other_txt2",
      //       },
      //       {
      //         title: "货品条形码",
      //         field_alias: "other_code",
      //       },
      //     ],
      //   },
      // ], // 信息列表
      activeName: "",
      search: "",
      rules: {
        template_name: [
          { required: true, message: "请填写名称", trigger: "blur" },
          { required: true, message: "请填写名称", trigger: "change" },
        ],
        size: [
          { required: true, message: "请填写尺寸", trigger: "blur" },
          { required: true, message: "请填写尺寸", trigger: "change" },
        ],
      }, // 表单规则
      form: {
        offset: -4,
        width: 210,
        height: 140,
        size: "210×140",
        gap: 0,
        is_separated: 0,
        picpath: '',
      }, // 表单数据
      typeList: [], // 模板类型
      barcodeType: [], // 一维码类型
      px_to_mm: '', // px换mm的比例
    };
  },
  created() {
    this.getRatio();
    // 获取一维码类型
    this.getBarcodeType();
    this.token = storage.session.get("token") || Cookies.get("token");
    this.baseUrl = baseUrl;
    this.getCustomTemplateType();
    if (this.row.print_template_local_id) {
      this.getCustomTemplateInfo();
    }
  },
  props: {
    row: Object,
  },
  methods: {
    // 添加二维码内容
    handleQrCodeValue(e, item) {
      // 找中文
      // let fontValue = '';
      // this.qrCodeValueList.forEach((item) => {
      //   if (item.field_alias === e) {
      //     chValue = '${' + item.title+ '}';
      //   }
      // })
      const input = document.getElementById("qrInput");
      // 光标位置
      let cursurPosition = -1;
      if (typeof input.selectionStart == "number") {//非IE浏览器
          cursurPosition = input.selectionStart;
      } else {//IE
          var range = document.selection.createRange();
          range.moveStart("character", -input.value.length);
          cursurPosition = range.text.length;
      }
      let value = item.value;
      // 分割成两段
      const value1 = value.substring(0, cursurPosition);
      const value2 = value.substring(cursurPosition, value.length);
      let param = '${' + e + '}';
      value = `${value1}${param}${value2}`;
      item.value = value;
      this.qrCodeValue = '';
    },
    getRatio() {
      // 获取比例
      this.$nextTick(() => {
        const px_width = document.getElementById('px_content').offsetWidth;
        const mm_width = document.getElementById('mm_content').offsetWidth;
        this.ratio = Number(numeral(px_width / mm_width).format('0.00'));
        this.px_to_mm = Number(numeral(px_width / 210).format('0.00'));
        console.log(this.px_to_mm);
      });
    },
    handleBig() {
      if (this.multiple === 5) {
        return;
      }
      this.multiple += 0.5;
    },
    handleSmall() {
      if (this.multiple === 0.5) {
        return;
      }
      this.multiple -= 0.5;
    },
    handleCodeInput(e, value, field_alias) {
      if (field_alias.indexOf('other_code') === -1) {
        return;
      }
      // 节流
      if (this.timer2) {
        clearInterval(this.timer2);
      }
      this.$nextTick(() => {
        this.itemList.forEach((item) => {
          if (item.field_alias.indexOf('other_code') > -1) {
            item[value] = e;
          }
        })
        this.timer2 = setTimeout(() => {
          if (this.itemForm.field_alias.indexOf('other_code') > -1) {
            this.getGoodsBarcode();
          }
        }, 200);
      })
    },
    // 获取一维码
    getGoodsBarcode() {
      if (!this.itemForm || !this.itemForm.height || !this.itemForm.barcode_type || !this.itemForm.width_factor || !this.itemForm.number_digit) {
        return;
      }
      getGoodsBarcodeReq({
          height: Number(this.itemForm.height),
          barcode_type: Number(this.itemForm.barcode_type),
          width_factor: Number(this.itemForm.width_factor),
          number_digit: Number(this.itemForm.number_digit),
        })
        .then((res) => {
          if (res.code === 1) {
            if (res.data.status === 1) {
              this.itemList.forEach((item) => {
                if (item.field_alias === 'other_code') {
                  item.barcode = res.data.barcode;
                }
              })
              this.itemForm = { ...this.itemForm, barcode: res.data.barcode };
            } else {
              this.$message.warning('条码类型不支持');
            }
          }
        })
    },
    // 获取一维码类型
    getBarcodeType() {
      getBarcodeTypeReq()
        .then((res) => {
          if (res.code === 1) {
            this.barcodeType = res.data;
          }
        });
    },
    handleImgSuccess(res, file) {
      this.form.picpath = res.data;
    },
    // 旧料分开打印
    handleSwitch(e) {
      if (e) {
        this.checkList.push('other_table2');
        this.$nextTick(() => {
          this.onCheckBox('other_table2');
        });
      } else {
        this.checkList = this.checkList.filter((item) => {
          return item !== 'other_table2';
        });
        this.$nextTick(() => {
          this.onCheckBox('other_table2');
        });
      }
    },
    handleAdd() {
    },
    handleReduce() {
    },
    // 获取表格数据源
    getTemplateGoodsList(goods_type ) {
      getTemplateGoodsListReq({
        type: this.form.type,
        goods_type,
      })
        .then((res) => {
          if (res.code === 1) {
            if (goods_type === 10) {
              this.sourceData['other_table1'] = res.data;
            } else if (goods_type === 20) {
              this.sourceData['other_table2'] = res.data;
            }
          }
        });
    },
    // 获取模板信息
    getCustomTemplateInfo() {
      getCustomTemplateInfoReq({
        print_template_local_id: this.row.print_template_local_id,
      })
        .then((res) => {
          if (res.code === 1) {
            if (res.data.gap) {
              res.data.height = res.data.height - res.data.gap;
            }
            this.form = {
              template_name: res.data.template_name,
              width: res.data.width,
              height: res.data.height,
              type: res.data.type,
              gap: res.data.gap || 0,
              size: `${res.data.width}×${res.data.height}`,
              is_separated: res.data.is_separated,
              picpath: res.data.picpath,
              offset: res.data.offset,
            };
            this.getCustomTemplateFields();
            this.getTemplateGoodsList(10);
            this.getTemplateGoodsList(20);
            this.itemList = JSON.parse(res.data.item_list);
            this.checkList = this.itemList.map((item) => {
              return item.field_alias;
            });
            // 找到当前的
            this.itemForm = this.itemList[this.itemList.length - 1];
          }
        });
    },
    // 返回
    handleBack() {
      this.$emit('onBack');
    },
    // 选择事件
    handleChangeSelect() {
      this.checkList = [];
      this.itemList = [];
      this.itemForm = {};
      this.form = { ...this.form, is_separated: 0 };
      this.getCustomTemplateFields();
      this.getTemplateGoodsList(10);
      this.getTemplateGoodsList(20);
      if (this.form.type === 50) {
        this.form.offset = 0;
      } else {
        this.form.offset = -4;
      }
    },
    // 获取类别
    getCustomTemplateType() {
      getCustomTemplateTypeReq()
        .then((res) => {
          if (res.code === 1) {
            this.typeList = res.data;
            if (!this.row.print_template_local_id) {
              this.form.type = res.data[0].type;
              this.getCustomTemplateFields();
              this.getTemplateGoodsList(10);
              this.getTemplateGoodsList(20);
            }
          }
        });
    },
    // 获取左侧字段
    getCustomTemplateFields() {
      this.loading = true;
      this.activeName = '';
      getCustomTemplateFieldsReq({
        title: this.title,
        type: this.form.type,
      })
        .then((res) => {
          if (res.code === 1) {
            this.$nextTick(() => {
              this.infoList = res.data.infoList;
              const obj = {};
              this.infoList.forEach((info) => {
                info.children.forEach((item) => {
                  obj[item.field_alias] = item;
                });
              });
              this.infoObj = obj;
              // 获取组成二维码的变量
              this.qrCodeValueList = JSON.parse(JSON.stringify(this.infoList[0].children));
              this.loading = false;
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 保存
    handleSave() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (!this.itemList.length) {
            this.$message.warning('请不要保存空模板！');
            return;
          }
          function html_decode(str) {
              var s = "";
              if (str.length == 0) return "";
              s = str.replace(/&amp;/g, "&");
              s = s.replace(/&lt;/g, "<");
              s = s.replace(/&gt;/g, ">");
              s = s.replace(/&nbsp;/g, " ");
              s = s.replace(/&#39;/g, "\'");
              s = s.replace(/&quot;/g, "\"");
              s = s.replace(/<br\/>/g, "\n");
              return s;
          }
          // 表格的需要把html结构传过去
          this.itemList.forEach((item) => {
            if (item.field_alias.indexOf('other_table') > -1) {
              item.html = html_decode(document.getElementById(item.field_alias).innerHTML);
            }
          })
          const content = html_decode(document.getElementById('content').innerHTML);
          const font_content = html_decode(document.getElementById('font_content').innerHTML);
          this.itemList = this.itemList.map((item) => {
            let obj = { ...item };
            // 需要用到
            if (item.field_alias === 'other_code') {
              obj.px_width = document.getElementById('code').offsetWidth;
              obj.px_height = document.getElementById('code').offsetHeight;
            }
            if (item.field_alias === 'other_qrcode') {
              obj.px_width = document.getElementById('qrcode').offsetWidth;
              obj.px_height = document.getElementById('qrcode').offsetHeight;
            }
            return obj;
          })
          if (!this.row.print_template_local_id) {
            createCustomTemplateReq({
              content,
              font_content,
              ...this.form,
              height: numeral(Number(this.form.height) + Number(this.form.gap)).format('0.00'),
              item_list: JSON.stringify(this.itemList),
            })
              .then((res) => {
                if (res.code === 1) {
                  this.$message.success('保存成功');
                  this.$emit('onBack');
                  this.$emit('getList');
                }
              });
          } else {
            updateCustomTemplateReq({
              print_template_local_id: this.row.print_template_local_id,
              content,
              font_content,
              ...this.form,
              height: numeral(Number(this.form.height) + Number(this.form.gap)).format('0.00'),
              item_list: JSON.stringify(this.itemList),
            })
              .then((res) => {
                if (res.code === 1) {
                  this.$message.success('更新成功');
                  this.$emit('onBack');
                  this.$emit('getList');
                }
              });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 选择数据源
    handleSelect(item, data) {
      this.$set(data, "font_name", "${" + item.title + "}");
      this.$set(data, "back_name", "${" + item.field_alias + "}");
    },
    // 双击表格的项
    handleTabelCurr(key, field, field_alias) {
      let [tableItem] = this.itemList.filter((item) => {
        return item.field_alias === field_alias;
      });
      [this.itemForm] = tableItem[field].filter((item) => {
        return item.key === key;
      });
    },
    // 删除列
    handleDeleColumns(field_alias) {
      const itemList = JSON.parse(JSON.stringify(this.itemList));
      let [tableItem] = itemList.filter((item) => {
        return item.field_alias === field_alias;
      });
      if (tableItem.titles.length === 1) {
        this.$message.warning("表格列数最少为1");
        return;
      }
      tableItem.titles.pop();
      tableItem.column.pop();
      this.itemList = itemList;
      console.log(tableItem);
    },
    // 新增列
    handleAddColumns(field_alias) {
      const itemList = JSON.parse(JSON.stringify(this.itemList));
      let [tableItem] = itemList.filter((item) => {
        return item.field_alias === field_alias;
      });
      let Num = tableItem.titles[tableItem.titles.length - 1].key.replace(
        /[^0-9]/gi,
        ""
      );
      Num = Number(Num) + 1;
      tableItem.titles.push({
        title: "双击填写标题",
        key: `${field_alias}title${Num}`,
        width: 25,
        height: 5,
        fontSize: '12',
        lineHeight: 5,
        textAlign: 'center',
        fontFamily: '微软雅黑',
      });
      tableItem.column.push({
        title: "双击绑定数据源",
        key: `${field_alias}column${Num}`,
        width: 25,
        height: 5,
        fontSize: '12',
        lineHeight: 5,
        textAlign: 'center',
        fontFamily: '微软雅黑',
      });
      this.itemList = itemList;
      console.log(tableItem);
    },
    handlePrint() {
      LODOP = getLodop();
      LODOP.PRINT_INIT("打印控件功能演示_Lodop功能_全页");
      LODOP.ADD_PRINT_HTM(
        0,
        0,
        "100%",
        "100%",
        document.documentElement.innerHTML
      );
      LODOP.PREVIEW();
    },
    // 改变尺寸
    changeSize(e, field_alias) {
      const [currItem] = this.itemList.filter((item) => {
        return item.field_alias === field_alias;
      });
      // 找到当前的
      const currentArr = this.itemList.filter((item) => {
        return item.field_alias === field_alias;
      });
      [this.itemForm] = currentArr;
      const odiv = e.target;
      const parentNode = odiv.parentNode.parentNode;
      // 相对盒子的坐标x
      const scrollLeft = odiv.parentNode.parentNode.parentNode.scrollLeft;
      const scrollTop = odiv.parentNode.parentNode.parentNode.scrollTop;
      const startX = e.clientX - odiv.parentNode.parentNode.offsetLeft + scrollLeft;
      const startY = e.clientY - odiv.parentNode.parentNode.offsetTop + scrollTop;
      const parentWidth = odiv.parentNode.clientWidth;
      const parentHeight = odiv.parentNode.clientHeight;
      document.onmousemove = (e) => {
        let lastX = e.clientX - odiv.parentNode.parentNode.offsetLeft + scrollLeft;
        let lastY = e.clientY - odiv.parentNode.parentNode.offsetTop + scrollTop;
        // 只能拉到纸张大小
        // if (lastX > (odiv.parentNode.parentNode.offsetWidth - 3) * this.multiple) {
        //   lastX = (odiv.parentNode.parentNode.offsetWidth - 3) * this.multiple;
        // }
        // if (lastY > (odiv.parentNode.parentNode.offsetHeight - 3) * this.multiple) {
        //   lastY = (odiv.parentNode.parentNode.offsetHeight - 3) * this.multiple;
        // }
        let width;
        let height;
        if (currItem.rotate === '0' || currItem.rotate === '360') {
          width = (lastX - startX) / this.multiple;
          height = (lastY - startY) / this.multiple;
        }
        if (currItem.rotate === '90') {
          width = (startX - lastX) / this.multiple;
          height = (lastY - startY) / this.multiple;
        }
        if (currItem.rotate === '180') {
          width = (startX - lastX) / this.multiple;
          height = (startY - lastY) / this.multiple;
        }
        if (currItem.rotate === '270') {
          width = (lastX - startX) / this.multiple;
          height = (startY - lastY) / this.multiple;
        }
        // 限制大小
        if (currItem.rotate === '0' || currItem.rotate === '180' || currItem.rotate === '360') {
          if (parentWidth + width < 15) {
            odiv.parentNode.style.width = 15 +'px';
          } else {
            odiv.parentNode.style.width = parentWidth + width + 'px';
          }
          if (parentHeight + height < 15) {
            odiv.parentNode.style.height = 15 + 'px';
          } else {
            odiv.parentNode.style.height = parentHeight + height + 'px';
          }
        }
        if (currItem.rotate === '90' || currItem.rotate === '270') {
          if (parentWidth + height < 15) {
            odiv.parentNode.style.width = 15 +'px';
          } else {
            odiv.parentNode.style.width = parentWidth + height + 'px';
          }
          if (parentHeight + width < 15) {
            odiv.parentNode.style.height = 15 + 'px';
          } else {
            odiv.parentNode.style.height = parentHeight + width + 'px';
          }
        }

        // 此处需要节流
        if (this.timer) {
          clearInterval(this.timer);
        }
        this.timer = setTimeout(() => {
          currItem.width = numeral(odiv.parentNode.offsetWidth / this.px_to_mm).format('0.00');
          currItem.height = numeral(odiv.parentNode.offsetHeight / this.px_to_mm).format('0.00');
        }, 15);
      };
      document.onmouseup = () => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    // 移动
    handleMove(e, field_alias, level) {
      const px_content = document.getElementById('px_content');
      const [currItem] = this.itemList.filter((item) => {
        return item.field_alias === field_alias;
      });
      // 找到当前的
      const currentArr = this.itemList.filter((item) => {
        return item.field_alias === field_alias;
      });
      [this.itemForm] = currentArr;
      let odiv;
      if (!level) {
        odiv = e.target;
      } else if (level === 1) {
        odiv = e.target.parentNode;
      } else if (level === 2) {
        odiv = e.target.parentNode.parentNode;
      }
      // console.log('鼠标点击的位置', e.clientX, 'dom元素的左边距', odiv.offsetLeft);
      // 算出鼠标相对元素的位置
      const disX = e.clientX / this.multiple - odiv.offsetLeft;
      const disY = e.clientY / this.multiple - odiv.offsetTop;
      // console.log('disX:', disX);
      // console.log(this.multiple);
      let left;
      let top;
      document.onmousemove = (e) => {
        // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        left = e.clientX / this.multiple - disX;
        top = e.clientY / this.multiple - disY;
        // console.log(left)
        // 绑定元素位置到positionX和positionY上面
        // 限制left 和 top
        // if (left < 0) {
        //   left = 0;
        // } else if (left + odiv.offsetWidth > px_content.offsetWidth) {
        //   left = px_content.offsetWidth - odiv.offsetWidth;
        // }
        // if (top < 0) {
        //   top = 0;
        // } else if (top + odiv.offsetHeight > px_content.offsetHeight) {
        //   top = px_content.offsetHeight - odiv.offsetHeight;
        // }
        // 移动当前元素
        odiv.style.left = left  + "px";
        odiv.style.top = top + "px";
        return false;
      };
      document.onmouseup = () => {
        if (left || left === 0) {
          currItem.positionX = left;
        }
        if (top || top === 0) {
          currItem.positionY = top;
        }
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    // checkbox
    onCheckBox(field_alias) {
      // 右侧表单的默认值
      let item = {
        width: "30",
        height: "5",
        rotate: "0",
        fontSize: "12",
        lineHeight: "5",
        customText: "",
        lineShow: "",
        lineStyle: "",
        titleShow: "",
        pageSize: "",
        textAlign: 'center',
        positionX: 0,
        positionY: 0,
        value: '',
        fontFamily: '微软雅黑',
      };
      if (field_alias.indexOf('other_code') > -1) {
        item.width = '26';
        item.height = '6';
        item.barcode_type = this.barcodeType[0].type_id;
        item.width_factor = 1;
        item.number_digit = 10;
        item.showNumber = 1;
      }
      if (field_alias.indexOf('other_qrcode') > -1) {
        item.width = '10';
        item.height = '10';
      }
      // 新增表格的类目
      let tableItem = {
        rotate: "0",
        positionX: 0,
        positionY: 0,
        lineShow: 1,
        titleShow: 1,
        pageSize: 5,
        isPage: 0,
        value: '',
        fontFamily: '微软雅黑',
        titles: [
          {
            title: "双击填写标题",
            key: `${field_alias}title1`,
            width: 25,
            height: 5,
            fontSize: '12',
            lineHeight: 5,
            textAlign: 'center',
            fontFamily: '微软雅黑',
          },
          {
            title: "双击填写标题",
            key: `${field_alias}title2`,
            width: 25,
            height: 5,
            fontSize: '12',
            lineHeight: 5,
            textAlign: 'center',
            fontFamily: '微软雅黑',
          },
          {
            title: "双击填写标题",
            key: `${field_alias}title3`,
            width: 25,
            height: 5,
            fontSize: '12',
            lineHeight: 5,
            textAlign: 'center',
            fontFamily: '微软雅黑',
          },
        ],
        column: [
          {
            title: "双击绑定数据源",
            key: `${field_alias}column1`,
            width: 25,
            height: 5,
            fontSize: '12',
            lineHeight: 5,
            field_alias: "",
            textAlign: 'center',
            fontFamily: '微软雅黑',
          },
          {
            title: "双击绑定数据源",
            key: `${field_alias}column2`,
            width: 25,
            height: 5,
            fontSize: '12',
            lineHeight: 5,
            field_alias: "",
            textAlign: 'center',
            fontFamily: '微软雅黑',
          },
          {
            title: "双击绑定数据源",
            key: `${field_alias}column3`,
            width: 25,
            height: 5,
            fontSize: '12',
            lineHeight: 5,
            field_alias: "",
            textAlign: 'center',
            fontFamily: '微软雅黑',
          },
        ],
        font: '<w:p>${goodsList}</w:p>',
        back: '<w:p>${/goodsList}</w:p>',
      };
      if (field_alias === 'other_table2') {
        tableItem = {...tableItem, font: '<w:p>${rawGoodsList}</w:p>', back: '<w:p>${/rawGoodsList}</w:p>'};
      }
      // 指令打印 新增字段type区分类型
      if (field_alias.indexOf('other_code') > -1) {
        // 如果是一维码
        item = { ...item, type: 'code' }
      } else if(field_alias.indexOf('other_qrcode') > -1) {
        // 如果是二维码
        item = { ...item, type: 'qrcode' }
      } else if (field_alias.indexOf('other_table') > -1) {
        tableItem = { ...tableItem, type: 'table' }
      } else if(field_alias.indexOf('other_txt') > -1) {
        item = { ...item, type: 'custom_text' }
      } else {
        item = { ...item, type: 'text' }
      }
      const arr = [];
      this.checkList.forEach((_) => {
        arr.push(this.infoObj[_]);
      });
      if (!this.itemList.length) {
        if (field_alias.indexOf('other_table') > -1) {
          this.itemList = [
            {
              ...arr[0],
              ...tableItem,
              font_name: "${" + arr[0].title + "}",
              back_name: "${" + arr[0].field_alias + "}",
              label: arr[0].title,
            },
          ];
        } else if (field_alias.indexOf('other_table') === -1) {
          this.itemList = [
            {
              ...arr[0],
              ...item,
              font_name: "${" + arr[0].title + "}",
              back_name: "${" + arr[0].field_alias + "}",
              label: arr[0].title,
            },
          ];
        }
        this.itemForm = this.itemList[this.itemList.length - 1];
        // this.getGoodsBarcode()
        return;
      }
      const newArr = [];
      arr.forEach((check) => {
        let m = 0;
        this.itemList.forEach((data) => {
          if (check.field_alias === data.field_alias) {
            newArr.push({
              ...data,
              font_name: "${" + check.title + "}",
              back_name: "${" + check.field_alias + "}",
              label: check.title,
            });
          } else {
            m += 1;
            if (m === this.itemList.length) {
              if (field_alias.indexOf('other_table') === -1) {
                newArr.push({
                  ...check,
                  ...item,
                  font_name: "${" + check.title + "}",
                  back_name: "${" + check.field_alias + "}",
                  label: check.title,
                });
              } else if (field_alias.indexOf('other_table') > -1) {
                newArr.push({
                  ...check,
                  ...tableItem,
                  font_name: "${" + check.title + "}",
                  back_name: "${" + check.field_alias + "}",
                  label: check.title,
                });
              }
            }
          }
        });
      });
      this.itemList = newArr;
      this.itemForm = this.itemList[this.itemList.length - 1];
      // this.getGoodsBarcode()
      console.log(this.itemList);
      console.log(this.itemForm);
    },
    // 设置背景
    handleSetBg() {
      this.$message.warning("请先填写尺寸");
    },
    // 清空背景图
    clearBg() {
      this.$refs["my-upload"].clearFiles();
      this.form.picpath = "";
    },
    handleExceed() {
      this.$message.warning("已有背景图");
    },
    handleChange(file) {
      // 展示背景图
      // this.bgUrl = URL.createObjectURL(file.raw);
    },
    // 处理尺寸
    handleSize() {
      const form = { ...this.form };
      if (form.width && form.height) {
        form.size = `${form.width}×${form.height}`;
      } else {
        form.size = "";
      }
      this.form = form;
    },
  },
};
</script>

<style lang="less" scoped>
.right {
  background: white;
  border: 1px solid #ddd;
  box-sizing: border-box;
  padding: 10px;
  width: 300px;
  min-height: calc(100vh - 170px);
  flex-shrink: 0;
  position: fixed;
  right: 10px;
}
.wrapper {
  display: flex;
}
.middle {
  border: 1px solid #ddd;
  height: calc(100vh - 170px);
  box-sizing: border-box;
  padding: 10px;
  width: calc(100% - 500px);
  overflow: auto;
  background: rgb(90,90,90);
}
.top {
  background: white;
  padding: 20px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
}
.top /deep/ .el-form-item {
  margin-bottom: 0px;
}
.size {
  display: flex;
}
.left {
  background: white;
  width: 200px;
  height: calc(100vh - 170px);
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 10px;
  box-sizing: border-box;
  flex-shrink: 0;
  .el-icon-search {
    cursor: pointer;
  }
  /deep/ .el-checkbox {
    display: block;
  }
}
</style>
